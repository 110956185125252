//menu button interaction
const menuControl = document.querySelectorAll( '.js--menu-control' );
const navigation = document.querySelector( '.js--site-navigation' );
menuControl.forEach( ( menuButton ) => {
	menuButton.addEventListener( 'click', () => {
		menuButton.classList.toggle('open');
		navigation.classList.toggle('open');
	})
});

//open din info
const dinInfoButton = document.querySelectorAll( '.js--open-din-info' );
const dinInfoModal = document.querySelector( '.js--modal-1' );
const wrongDinModal = document.querySelector( '.js--modal-2' );
const modalCloseButton = document.querySelectorAll( '.js--close-modal' );

dinInfoButton.forEach( ( dinInfoBtn ) => {
	dinInfoBtn.addEventListener( 'click', () => {
		dinInfoModal.classList.add('visible');
		document.body.classList.add('modal-open');
	});
});

//close all gate modals
function closeModal(e) {
    e.preventDefault();
    e.target.closest('.js--modal').classList.remove('visible');
    document.body.classList.remove('modal-open');
}

modalCloseButton.forEach( ( modalCloseBtn ) => {
	modalCloseBtn.addEventListener( 'click', closeModal );
});


function postForm(form) {
    const data = new URLSearchParams();
    for (const pair of new FormData(form)) {
        data.append(pair[0], pair[1]);
    }

    return fetch("/", {
        method: 'post',
        body: data
    }).then(response => response.json());
}

function authFormSubmit(e) {
    e.preventDefault();
    postForm(e.target).then(json => {
        if (json.status === 'ok') {
            window.location = json.route;
        } else {
            e.target.querySelector('.site-gate-error').style.display = "flex";
        }
    });
}

var hcpForm = document.getElementById("hcp-form");
if (hcpForm) hcpForm.addEventListener("submit", authFormSubmit);

var patientForm = document.getElementById("patient-form");
if (patientForm) patientForm.addEventListener("submit", authFormSubmit);

var msgForm = document.getElementById("message-form");
if (msgForm) msgForm.addEventListener("submit", function(e) {
    e.preventDefault();
    postForm(e.target).then(json => {
        e.target.classList.add("-submitted");
    });
});

var backLink = document.getElementById("back-link");
if (backLink) backLink.addEventListener("click", function(e) {
    e.preventDefault();
    history.back();
});


var externalLinks = document.querySelectorAll("a.external");
var interstitual = document.querySelector(".js--interstitual");
externalLinks.forEach( ( link ) => {
	link.addEventListener( 'click', (e) => {
        e.preventDefault();
		interstitual.classList.add('visible');
		document.body.classList.add('modal-open');
        interstitual.querySelector("a.continue-btn").href = link.href;
	});
});